exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recommendations-austin-taco-project-js": () => import("./../../../src/pages/recommendations/austin-taco-project.js" /* webpackChunkName: "component---src-pages-recommendations-austin-taco-project-js" */),
  "component---src-pages-recommendations-curras-grill-js": () => import("./../../../src/pages/recommendations/curras-grill.js" /* webpackChunkName: "component---src-pages-recommendations-curras-grill-js" */),
  "component---src-pages-recommendations-grannys-tacos-js": () => import("./../../../src/pages/recommendations/grannys-tacos.js" /* webpackChunkName: "component---src-pages-recommendations-grannys-tacos-js" */),
  "component---src-pages-recommendations-gueros-taco-bar-js": () => import("./../../../src/pages/recommendations/gueros-taco-bar.js" /* webpackChunkName: "component---src-pages-recommendations-gueros-taco-bar-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-recommendations-las-trancas-js": () => import("./../../../src/pages/recommendations/las-trancas.js" /* webpackChunkName: "component---src-pages-recommendations-las-trancas-js" */),
  "component---src-pages-recommendations-nixta-taqueria-js": () => import("./../../../src/pages/recommendations/nixta-taqueria.js" /* webpackChunkName: "component---src-pages-recommendations-nixta-taqueria-js" */),
  "component---src-pages-recommendations-suerte-js": () => import("./../../../src/pages/recommendations/suerte.js" /* webpackChunkName: "component---src-pages-recommendations-suerte-js" */),
  "component---src-pages-recommendations-thai-fresh-js": () => import("./../../../src/pages/recommendations/thai-fresh.js" /* webpackChunkName: "component---src-pages-recommendations-thai-fresh-js" */),
  "component---src-pages-recommendations-tysons-tacos-js": () => import("./../../../src/pages/recommendations/tysons-tacos.js" /* webpackChunkName: "component---src-pages-recommendations-tysons-tacos-js" */),
  "component---src-pages-recommendations-valentinas-tex-mex-bbq-js": () => import("./../../../src/pages/recommendations/valentinas-tex-mex-bbq.js" /* webpackChunkName: "component---src-pages-recommendations-valentinas-tex-mex-bbq-js" */),
  "component---src-pages-recommendations-vegan-nom-js": () => import("./../../../src/pages/recommendations/vegan-nom.js" /* webpackChunkName: "component---src-pages-recommendations-vegan-nom-js" */),
  "component---src-pages-recommendations-velvet-taco-js": () => import("./../../../src/pages/recommendations/velvet-taco.js" /* webpackChunkName: "component---src-pages-recommendations-velvet-taco-js" */),
  "component---src-pages-recommendations-veracruz-all-natural-js": () => import("./../../../src/pages/recommendations/veracruz-all-natural.js" /* webpackChunkName: "component---src-pages-recommendations-veracruz-all-natural-js" */),
  "component---src-pages-taco-battles-fish-n-chips-versus-grilled-fish-taco-js": () => import("./../../../src/pages/taco-battles/fish-n-chips-versus-grilled-fish-taco.js" /* webpackChunkName: "component---src-pages-taco-battles-fish-n-chips-versus-grilled-fish-taco-js" */),
  "component---src-pages-taco-battles-js": () => import("./../../../src/pages/taco-battles.js" /* webpackChunkName: "component---src-pages-taco-battles-js" */),
  "component---src-pages-taco-battles-suadero-versus-smoked-brisket-js": () => import("./../../../src/pages/taco-battles/suadero-versus-smoked-brisket.js" /* webpackChunkName: "component---src-pages-taco-battles-suadero-versus-smoked-brisket-js" */),
  "component---src-pages-taco-battles-veggie-taco-versus-grilled-avocado-reale-js": () => import("./../../../src/pages/taco-battles/veggie-taco-versus-grilled-avocado-reale.js" /* webpackChunkName: "component---src-pages-taco-battles-veggie-taco-versus-grilled-avocado-reale-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

